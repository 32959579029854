import React, { useContext, useEffect, useRef, useState } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import BlockCTA from "~/components/configurable/BlockCTA"
import Col6 from "~/components/grid/Col6"
import { window } from 'global';
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import Col3 from "../grid/Col3"
import Col2 from "../grid/Col2"
import Col9 from "../grid/Col9"
import Col10 from "../grid/Col10"
import "./certifications.scss"
import { Autocomplete } from "@material-ui/lab"

const Certifications = ({ location }) => {

  return (
    <div className="certificates-page">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
        Our Certifications & Accreditations 
        </Heading>
      </Hero>
      <Block className="segBlock">
        <Section>
          <div className="container container--column ">
            <Col12>
              <Heading level={1}>Quality, expertise, competence, trustworthiness...</Heading>
              <p className="bold-formatting">
                At Green Energy Together we aim to provide the highest level of service to our customers and internal quality controls to ensure that all our products and services are delivered at the highest standard. Our industry-recognised <strong>national and international certifications</strong> say it all. 
              </p>
              <div className="certificates-page-ind">

                <div className="row" style={{ marginTop: 70, }} >
                  <Col2>
                    <div style={{height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img  src={require('../../images/cert/epvs.jpg')} 
                      alt="" 
                      style = {{
                        maxHeight:200,
                        margin: "auto"
                      }}
                      />
                    </div>
                  </Col2>
                  <Col10 className="right_top" >
                    <Heading level={3} underlined >EPVS </Heading>
                    <p className="bold-formatting">
                    Once an installer becomes a member, they are committed to providing consumers with <strong>accurate performance estimates and monetary savings</strong> in accordance with our certification standard. The Energy Performance Validation Scheme (EPVS) is designed to negate issues and by using an EPVS member you can feel assured that the installer has gone through a rigorous onboarding process and has satisfied us that its paperwork is clear, understandable and correct.
                    </p>
                  </Col10>
                </div>


                <div className="row" style={{ marginTop: 70, }} >
                  <Col2>
                    <div style={{height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img  src={require('../../images/cert/elmapp.jpg')} 
                      alt="" 
                      style = {{
                        maxHeight:200,
                        margin: "auto"
                      }}
                      />
                    </div>
                  </Col2>
                  <Col10 className="right_top" >
                    <Heading level={3} underlined >Elmhurst Energy Accredited Member</Heading>
                    <p className="bold-formatting">
                    Elmhurst’s accreditation scheme ensures DEAs (Domestic Energy Assessor) have the skills and knowledge to <strong>deliver professional energy assessments</strong>, and lodge Energy Performance Certificates (EPCs) in England, Wales, Scotland and Northern Ireland. 
                    </p>
                  </Col10>
                </div>
                <div className="row" style={{ marginTop: 70, }} >
                  <Col2>
                    <div style={{height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img  src={require('../../images/cert/iso9001.jpg')} 
                      alt="" 
                      style = {{
                        maxHeight:200,
                        margin: "auto"
                      }}
                      />
                    </div>
                  </Col2>
                  <Col10 className="right_top" >
                    <Heading level={3} underlined >ISO9001 – Quality Management </Heading>
                    <p className="bold-formatting">
                    ISO 9001 is and international standard for the quality management system (QMS). This international standard is designed to be a powerful business improvement tool and is used to demonstrate the ability of an organisation to consistently provide products and services that <strong>meet customer and regulatory requirements.</strong> 
                    </p>
                  </Col10>
                </div>
                <div className="row" style={{ marginTop: 70, }} >
                  <Col2>
                    <div style={{height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img  src={require('../../images/cert/iso14001.jpg')} 
                      alt="" 
                      style = {{
                        maxHeight:200,
                        margin: "auto"
                      }}
                      />
                    </div>
                  </Col2>
                  <Col10 className="right_top" >
                    <Heading level={3} underlined >ISO14001 – Environmental Management</Heading>
                    <p className="bold-formatting">
                    ISO 14001 is the international standard that specifies the requirements for a company to operate an effective environmental management system (EMS). An EMS is used to aid a company in <strong>identifying and controlling the environmental impact</strong> of its activities, products, services, and continually improving its environmental performance.
                    </p>
                  </Col10>
                </div>
                <div className="row" style={{ marginTop: 70, }} >
                  <Col2>
                    <div style={{height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img  src={require('../../images/cert/iso45001.jpg')} 
                      alt="" 
                      style = {{
                        maxHeight:200,
                        margin: "auto"
                      }}
                      />
                    </div>
                    </Col2>
                  <Col10 className="right_top" >
                  
                    <Heading level={3} underlined >ISO45001 – Health & Safety Management</Heading>
                    <p className="bold-formatting">
                    Introduced in April 2021 and replacing OSHS 18001, ISO 45001 is the world’s international standard for occupational health and safety, issued to <strong>protect employees and visitors </strong>from work-related <strong>accidents and diseases. </strong>

                    </p>
                  </Col10>
                </div>
              </div>
            </Col12>
          </div>
        </Section>
      </Block>
    </div>
  )
}

export default Certifications
